import CallingStatus from './calling-status.enum';

const ClassStatus = {
    Pending: 'Pending',
    Working: 'Working',
    Finished: 'Finished',
    Delayed: 'Delayed',
    Dropped: 'Dropped',
};

const PhoneStatus = {
    PHONE_IS_INVALID: 'PHONE_IS_INVALID',
    PHONE_USER_IS_STUDENT: 'PHONE_USER_IS_STUDENT',
    PHONE_USER_IS_NOT_STUDENT: 'PHONE_USER_IS_NOT_STUDENT',
    PHONE_WAS_NOT_USED: 'PHONE_WAS_NOT_USED',
};

const EnrollmentStatus = {
    Attended: { id: 1, name: 'Attended' },
    Pending: { id: 2, name: 'Pending' },
    Late: { id: 3, name: 'Late' },
    Dropped: { id: 4, name: 'Dropped' },
};

const AttendanceStatus = {
    Pending: { id: 0, name: 'Pending' },
    Present: { id: 1, name: 'Present' },
    Late: { id: 2, name: 'Late' },
    Absent: { id: 3, name: 'Absent' },
    Excused: { id: 4, name: 'Excused' },
    NotYetEnrolled: { id: 5, name: 'Not Yet Enrolled' },
};

export default { ClassStatus, CallingStatus, PhoneStatus, EnrollmentStatus, AttendanceStatus };
