import React, { lazy } from 'react';
import _const from '../assets/const';
import { CenterConfigContextProvider } from '../context/center-config';

const AuthorizationScreens = lazy(() => import('./authorization.routes'));
const AdminScreens = lazy(() => import('./admin.routes'));
const EmployeeScreens = lazy(() => import('./employee.routes'));
const TATeacherScreens = lazy(() => import('./tateacher.routes'));

const AppRouteMachine = (props) => {
    const { isAuthenticated, role } = props;

    const ScreensIfAuthenticated = () => {
        switch (role) {
            case _const.Role.Admin:
                return <AdminScreens />;
            case _const.Role.Employee:
                return <EmployeeScreens />;
            case _const.Role.TA:
            case _const.Role.Teacher:
                return <TATeacherScreens />;
            default:
                return <TATeacherScreens />;
        }
    };

    return (
        <React.Suspense fallback="Verifying your account...">
            {!isAuthenticated && <AuthorizationScreens />}
            {isAuthenticated && (
                <CenterConfigContextProvider>
                    {ScreensIfAuthenticated()}
                </CenterConfigContextProvider>
            )}
        </React.Suspense>
    );
};

export default AppRouteMachine;
