const AuthorizationActionTypes = {
    REQUEST_LOG_IN: 'REQUEST_LOG_IN',
    REQUEST_LOG_IN_FULFILLED: 'REQUEST_LOG_IN_FULFILLED',
    REQUEST_LOG_IN_REJECTED: 'REQUEST_LOG_IN_REJECTED',
    REQUEST_VERIFY_ACCESS_TOKEN: 'REQUEST_VERIFY_ACCESS_TOKEN',
    REQUEST_VERIFY_ACCESS_TOKEN_FULFILLED: 'REQUEST_VERIFY_ACCESS_TOKEN_FULFILLED',
    REQUEST_VERIFY_ACCESS_TOKEN_REJECTED: 'REQUEST_VERIFY_ACCESS_TOKEN_REJECTED',
    SET_ACCESS_TOKEN: 'AUTH_SET_ACCESS_TOKEN',
    SET_REFRESH_TOKEN: 'AUTH_SET_REFRESH_TOKEN',
    SET_IS_AUTHENTICATED: 'SET_IS_AUTHENTICATED',
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_ROLE: 'SET_ROLE',
};

export default AuthorizationActionTypes;
