import React, { createContext, useContext, useState, useEffect } from 'react';
import apiMethods from '../http-client/api-methods';

const CenterConfigContext = createContext();

const CenterConfigContextProvider = ({ children }) => {
    const [data, setData] = useState({ classPlaces: [], classTypes: [], employees: [] });

    useEffect(() => {
        const fetchData = async () => {
            const classPlaces = await apiMethods.classInfo.getAllClassPlaces();
            const classTypes = await apiMethods.classInfo.getAllClassTypes();
            const employees = await apiMethods.employee.getAll();

            setData({
                classPlaces: classPlaces.data.data,
                classTypes: classTypes.data.data,
                employees: employees.data.data,
            });
        };
        if (data.classPlaces.length === 0 || data.classTypes === 0) {
            fetchData();
        }
    }, [data]);

    const findEmployeeInfoById = (id) => {
        if (!id || !data.employees.length) {
            return undefined;
        }
        return data.employees.find((e) => e.id === id);
    };

    const filterClassTypesOnCategory = (category) => {
        if (!category) {
            return data.classTypes;
        }
        if (!data.classTypes.length) {
            return [];
        }
        const classTypesOnCategory = data.classTypes
            .filter((t) => t.category === category)
            .map((type) => ({
                name: type.name,
                label: type.name,
                code: type.code,
                value: type.id,
                id: type.id,
            }));
        return classTypesOnCategory;
    };

    const contextValues = {
        state: data,
        dispatch: { findEmployeeInfoById, filterClassTypesOnCategory },
    };

    return (
        <CenterConfigContext.Provider value={contextValues}>
            {children}
        </CenterConfigContext.Provider>
    );
};

const useCenterConfigContext = () => {
    const context = useContext(CenterConfigContext);

    if (context === null) {
        throw new Error('useCenterConfigContext must be used within a CenterConfigContextProvider');
    }

    return context;
};

export { CenterConfigContextProvider, useCenterConfigContext };
