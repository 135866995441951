import httpClient from '../config/http-client';

const getAll = () => {
    return httpClient
        .get('/api/students')
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getPaging = ({ page = 1, pageSize = 10, name, phone }) => {
    const params = new URLSearchParams();
    params.append('page', page);
    params.append('pageSize', pageSize);
    name !== undefined && params.append('name', name);
    phone !== undefined && params.append('phone', phone);

    return httpClient
        .get('/api/students/paging', { params })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const createNewStudent = (entity) => {
    return httpClient
        .post('/api/students', entity)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getSingleStudent = (id) => {
    return httpClient
        .get(`/api/students/${id}`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getAllClassesByStudent = (studentId) => {
    return httpClient
        .get(`/api/students/${studentId}/class-status`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const checkPhoneIsMain = (phone) => {
    return httpClient
        .post(`/api/contacts/check-main-contact`, { phone })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const updateSingleStudent = (id, entity) => {
    return httpClient
        .put(`/api/students/${id}`, entity)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

export default {
    getAll,
    createNewStudent,
    getSingleStudent,
    checkPhoneIsMain,
    updateSingleStudent,
    getAllClassesByStudent,
    getPaging,
};
