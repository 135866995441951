import authorization from './authorization.api';
import currentUser from './currentUser.api';
import classInfo from './class.api';
import lesson from './lesson.api';
import student from './student.api';
import enrollment from './enrollment.api';
import employee from './employee.api';
import contact from './contact.api';
import logwork from './logwork.api';
import tuition from './tuition.api';

export default {
    authorization,
    classInfo,
    contact,
    currentUser,
    employee,
    enrollment,
    lesson,
    logwork,
    student,
    tuition,
};
