import httpClient from '../config/http-client';

const getAll = ({ fromDate, toDate, classPlaceId, typePaid }) => {
    const params = new URLSearchParams();
    fromDate && params.append('fromDate', fromDate);
    toDate && params.append('toDate', toDate);
    classPlaceId !== undefined && params.append('classPlace', classPlaceId);
    typePaid !== undefined && params.append('typePaid', typePaid);

    return httpClient
        .get(`/api/tuition`, { params })
        .then((result) => {
            return result.data.data;
        })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const updateMultipleTotal = (classId, entity) => {
    return httpClient
        .put(`/api/enrollments/classes/${classId}/change-fee-have-to-pay`, entity)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const updateMultiplePaid = (classId, entity) => {
    return httpClient
        .put(`/api/enrollments/classes/${classId}/change-fee-paid`, entity)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};
export default {
    getAll,
    updateMultipleTotal,
    updateMultiplePaid,
};
