import httpClient from '../config/http-client';

const getAllContactsByUser = (userId) => {
    return httpClient
        .get(`/api/contacts/user/${userId}`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const checkPhoneIsMain = (phone) => {
    return httpClient
        .post(`/api/contacts/check-main-contact`, { phone })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const updateMainContact = (id, phone) => {
    return httpClient
        .post(`/api/contacts/update-main-contact/user/${id}`, { phone })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

export default {
    checkPhoneIsMain,
    updateMainContact,
    getAllContactsByUser,
};
