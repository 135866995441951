import httpClient from '../config/http-client';

const getAll = (fromDate, toDate, classPlaceId, category, name) => {
    const params = new URLSearchParams();
    fromDate && params.append('fromDate', fromDate);
    toDate && params.append('toDate', toDate);
    classPlaceId && params.append('classPlace', classPlaceId);
    category && params.append('typeCategory', category);
    name && params.append('name', name);

    return httpClient
        .get('/api/log-work', { params })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getDetailForUser = (userID, fromDate, toDate, classPlaceId) => {
    return httpClient
        .get(
            `/api/log-work/detail/${userID}?fromDate=${fromDate}&toDate=${toDate}&classPlace=${classPlaceId}`,
        )
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

export default {
    getAll,
    getDetailForUser,
};
