import axios from 'axios';
import { authHeader } from './auth-header';

// config interceptors
const httpClient = axios.create();

let serverUrl = 'http://localhost:8080';
switch (process.env.REACT_APP_NODE_ENV) {
    case 'production':
        serverUrl = 'https://api.hpacademy.xyz';
        break;
    case 'development':
        serverUrl = 'https://apidev.hpacademy.xyz';
        break;
    case 'local':
    default:
        serverUrl = 'http://localhost:8080';
        break;
}

httpClient.defaults.baseURL = serverUrl;

httpClient.defaults.headers.post['Content-Type'] = 'application/json';
httpClient.interceptors.request.use(function (config) {
    config.headers.Authorization = authHeader().Authorization;
    return config;
});

httpClient.interceptors.response.use(
    function (response) {
        if (response.status === 200 && response.data && response.data.message) {
            // toastr.success(response.data.message);
        }
        return response;
    },
    function (error) {
        let errorResponse = error.response;

        if (errorResponse.status === 401) {
            // toastr.error(errorResponse.data.message);
        }

        if (errorResponse.status === 400) {
            // toastr.error(errorResponse.data.message);
        }

        if (errorResponse.status === 403) {
            // toastr.error(errorResponse.data.message);
        }

        return Promise.reject(error);
    },
);

export default httpClient;
