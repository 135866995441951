import httpClient from '../config/http-client';

const getAll = () => {
    return httpClient
        .get('/api/employees')
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const create = (entity) => {
    return httpClient
        .post('/api/employees', entity)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getEmployeeDetail = (id) => {
    return httpClient
        .get(`/api/employees/${id}`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const checkPhoneIsMain = (phone) => {
    return httpClient
        .post(`/api/contacts/check-main-contact`, { phone })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const update = (id, entity) => {
    return httpClient
        .put(`/api/employees/${id}`, entity)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));
};

const getCurrentWorkingEmployees = () =>
    httpClient
        .get(`/api/assignments/support-data-dropdown`)
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((err) => Promise.reject(err));

export default {
    getAll,
    create,
    getEmployeeDetail,
    checkPhoneIsMain,
    update,
    getCurrentWorkingEmployees,
};
